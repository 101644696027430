<template>
    <b-row class="justify-content-center mb-3 mt-3">
        <b-col sm="11" cols="11" md="10" lg="10">
            <h6 class="page-title pb-2">
                {{ $t("final-beneficiary-request-start.title") }}
            </h6>
            <b-card class="shadow-sm border-0 rounded-0 px-md-4" bg-variant="light" no-header>
                <b-card-text>
                    <b-row>
                        <b-col lg="6">
                            <b-form-group>

                                <b-row>
                                    <b-col cols="4">

                                        <label class="form-title">
                                            {{ $t("general.society-type") }}
                                        </label>
                                        <select class="form-select form-select-sm rounded-0" v-model="societyType">
                                            <option value="SA">
                                                {{ $t("society-types.sociedad anonima") }}
                                            </option>
                                            <option value="FU">
                                                {{
                                                    $t(
                                                        "society-types.fundacion de interes propio"
                                                    )
                                                }}
                                            </option>
                                            <option value="LI">
                                                {{ $t("society-types.sociedad limitada") }}
                                            </option>
                                            <option value="EX">
                                                {{ $t("society-types.sociedad extranjera") }}
                                            </option>
                                            <!-- <option value="CM">
                                                {{ $t("society-types.sociedad en comandita") }}
                                            </option>
                                            <option value="SEP">
                                                {{ $t("society-types.sociedad de emprendimiento") }}
                                            </option> -->
                                        </select>
                                    </b-col>
                                    <b-col cols="8">
                                        <label class="form-title">
                                            {{
                                                $t(
                                                    "final-beneficiary-request-start.folio"
                                                )
                                            }}*
                                        </label>
                                        <b-input-group>
                                            <b-form-input v-model="folio" @keypress.enter="addFolio" size="sm"
                                                class="rounded-0" />
                                            <b-input-group-append>
                                                <b-button class="main-btn" @click="addFolio">
                                                    <b-icon-plus scale="1.5" />
                                                </b-button>
                                            </b-input-group-append>
                                        </b-input-group>
                                    </b-col>
                                </b-row>
                                <b-row v-if="loading">
                                    <b-col cols="1">
                                        <div class="text-center text-primary my-2">
                                            <b-spinner class="align-middle" />
                                        </div>
                                    </b-col>
                                    <b-col cols="11">
                                        <div class="text-center text-primary my-2">
                                            <strong>Cargando datos del Registro Público ...</strong>
                                        </div>
                                    </b-col>
                                </b-row>
                            </b-form-group>
                            <b-form-group class="mt-3">
                                <label class="form-title">
                                    Números de folio
                                </label>
                                <b-row v-if="folios.length > 0" align-h="start">
                                    <b-col sm="6" lg="4" v-for="(item, f) in folios" :key="f">
                                        <div class="folio">
                                            <label>{{ item.societyType }} {{ item.folio }}</label>
                                            <b-button class="delete-corner" size="sm" variant="outline"
                                                @click="removeFolio(item)">
                                                <b-icon-x-circle scale="1.5" variant="danger" />
                                            </b-button>
                                        </div>
                                    </b-col>
                                </b-row>
                                <b-row v-else>
                                    <b-col>
                                        Introduzca almenos un folio para poder
                                        iniciar un proceso
                                    </b-col>
                                </b-row>
                            </b-form-group>
                            <b-form-group class="mt-3">
                                <label class="form-title">
                                    {{
                                        $t(
                                            "final-beneficiary-request-start.description"
                                        )
                                    }}
                                </label>
                                <b-form-textarea v-model="description" size="sm" class="rounded-0" rows="4" no-resize />
                            </b-form-group>
                        </b-col>
                        <b-col lg="6">
                            <b-form-group>
                                <label class="form-title">
                                    {{
                                        $t(
                                            "final-beneficiary-request-start.documents"
                                        )
                                    }}
                                </label>
                                <StartFinalBeneficiaryFiles :files="files" @delete="deleteFile" />
                                <div class="file-input mt-4 ms-3">
                                    <label for="file" class="btn main-btn btn-secondary">
                                        <b-icon-file-earmark-arrow-up class="me-1" />
                                        {{ $t("general.select-file") }}
                                    </label>
                                    <input ref="fileInput" id="file" type="file" multiple @change="onInputChange" />
                                </div>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row class="my-3">
                        <b-col class="text-end">
                            <b-overlay :show="sending" opacity="0.7" spinner-small spinner-variant="primary"
                                class="d-inline-block">
                                <b-button class="main-btn" :disabled="!allowStart" @click="startProcedure">
                                    {{
                                        $t(
                                            "final-beneficiary-request-start.send"
                                        )
                                    }}
                                </b-button>
                            </b-overlay>
                        </b-col>
                    </b-row>
                </b-card-text>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { RepositoryFactory } from "@/services/api";
import StartFinalBeneficiaryFiles from "./StartFinalBeneficiaryFiles.vue";
//import { checkPublicRegister } from "../../services/integration";
const uuidv4 = require("uuid/v4");

export default {
    name: "final-beneficiary-request-start",
    components: {
        StartFinalBeneficiaryFiles,
    },
    data() {
        return {
            sending: false,
            folio: "",
            societyType: "SA",
            folios: [],
            description: "",
            files: [],
            loading: false,
        };
    },
    mounted() {
        if (!this.allowOfficialStartProcedure) {
            this.$router.push({
                name: "procedures",
            });
        }
    },
    computed: {
        ...mapGetters("application", ["allowOfficialStartProcedure"]),
        allowStartProcedure() {
            return this.allowOfficialStartProcedure;
        },
        allowStart() {
            return this.folios.length > 0;
        },
    },
    methods: {
        ...mapActions("modals", ["openModal", "setModalInfo"]),
        async addFolio() {
            if (this.folio === "") {
                return;
            }
            let payload = {
                folio : this.folio,
                societyType: this.societyType,
                enabled: null
            };
            let exists = this.folios.find((f) => f.folio == payload.folio && f.societyType == payload.societyType) !== undefined;

            try {                
                let response = await RepositoryFactory.legalEntity.any(payload);

                if (!response.data) {
                    this.showErrorMessage("El folio ingresado no esta registrado en el sistema.");
                    return;
                }
                
                if (!exists) {
                    this.folios.push(payload);
                    this.folio = "";
                }
            } catch (error) {
                this.showErrorWSCatch(error);
                return;
            }

            //Pendiente por si se tiene que consultar RP
            // const result = await checkPublicRegister(this.folio, this.societyType);

            // if (result.code === 200) {
            //     if (!exists) {
            //         this.folios.push(payload);
            //         this.folio = "";
            //     }
            // }
            // else {
            //     this.loading = false;
            //     this.showErrorWSCatch(this.$t(result.data));
            // }
        },
        removeFolio(item) {
            const index = this.folios.findIndex(f => f === item);
            this.folios.splice(index, 1);
        },
        async startProcedure() {
            try {
                this.sending = true;
                let request = {
                    folios: this.folios,
                    description: this.description,
                };
                let formData = new FormData();
                formData.append("json", JSON.stringify(request));

                for (const file of this.files) {
                    formData.append("files", file.file);
                }

                await RepositoryFactory.procedure.startFinalBeneficiary(
                    formData
                );
                this.showSuccess();
            } catch {
                this.showError();
            } finally {
                this.sending = false;
            }
        },
        showSuccess() {
            this.setModalInfo({
                title: this.$t(`final-beneficiary-request-start.success.title`),
                message: this.$t(
                    `final-beneficiary-request-start.success.message`
                ),
                subMessage: this.$t(
                    `final-beneficiary-request-start.success.subMessage`
                ),
                routeName: "procedures",
            });
            this.openModal();
        },
        showError() {
            this.setModalInfo({
                title: this.$t(`final-beneficiary-request-start.error.title`),
                message: this.$t(
                    `final-beneficiary-request-start.error.message`
                ),
                subMessage: this.$t(
                    `final-beneficiary-request-start.error.subMessage`
                ),
            });
            this.openModal();
        },
        showErrorWS() {
            this.setModalInfo({
                title: "Error consultando registro público",
                message: "No se pudo encontrar el folio consultado en el servicio del registro público",
                subMessage: "Intente nuevamente o coloque un folio correcto",
            });
            this.openModal();
        },
        showErrorWSCatch(e) {
            this.setModalInfo({
                title: "Error consultando registro público",
                message: e,
                subMessage: "Intente nuevamente o coloque un folio correcto",
            });
            this.openModal();
        },
        showErrorMessage(e) {
            this.setModalInfo({
                title: "Aviso",
                message: e,
                subMessage: "Intente nuevamente o coloque otro folio",
            });
            this.openModal();
        },
        deleteFile(file) {
            let index = this.files.indexOf(file);
            this.files.splice(index, 1);
        },
        onInputChange(e) {
            let files = e.target.files;
            for (const file of files) {
                let item = {
                    id: uuidv4(),
                    filename: file.name,
                    file: file,
                };
                this.files.push(item);
            }
            this.$refs.fileInput.value = null;
        },
    },
};
</script>

<style lang="scss" scoped>
::placeholder {
    padding-left: 1rem;
    font-size: 13px;
}

hr {
    border: 1.3px solid #1c578f;
}

.folio {
    position: relative;

    margin-top: 0.5rem;
    border: 1px solid #b3b3b3;
    background-color: #ececec;
    border-radius: 0.25rem;

    label {
        width: 85%;
        text-align: left;
        padding: 0.5rem;
    }
}

.delete-corner {
    position: absolute;
    right: 0;
    top: 4px;
}
</style>