<template>
    <b-row>
        <b-col
            class="relative-content ps-4 file-container-border"
            cols="5"
            md="4"
            xl="3"
            v-for="file in files"
            :key="file.id"
            align-self="center"
        >
            <div class="icon-container text-center">
                <b-icon scale="4" :icon="getIconName(file.filename)" />
            </div>
            <p class="text-center">{{ file.filename }}</p>
            <b-button
                class="delete-corner p-2"
                size="sm"
                variant="outline"
                @click="remove(file)"
            >
                <b-icon-x-circle scale="1.7" variant="danger" />
            </b-button>
        </b-col>
    </b-row>
</template>

<script>
const re = /(?:\.([^.]+))?$/;

export default {
    name: "start-final-beneficiary-files",
    props: {
        files: {
            type: Array,
            required: true,
        },
    },
    methods: {
        remove(file) {
            this.$emit("delete", file);
        },
        getIconName(name) {
            let extension = re.exec(name)[1];
            if (["doc", "docx"].indexOf(extension) > -1) {
                return "file-earmark-word";
            }
            if (["xls", "xlsx"].indexOf(extension) > -1) {
                return "file-earmark-excel";
            }
            if (["jpg", "png"].indexOf(extension) > -1) {
                return "file-earmark-image";
            }
            if (["mp3", "mp4", "mkv", "avi"].indexOf(extension) > -1) {
                return "file-earmark-play";
            }
            return "file-earmark-text";
        },
    },
};
</script>

<style scoped>
.icon-container {
    padding: 2rem;
    color: black;
}

.delete-corner {
    position: absolute;
    top: 0;
    right: 0;
}

.relative-content {
    position: relative;
}

.file-container-border {
    background: rgba(0, 0, 0, 0.05);
    border-radius: 0% 0% 0% 0% / 0% 0% 0% 0%;
    box-shadow: 15px 15px rgba(0, 0, 0, 0.25);
    margin-left: 1.5rem;
    margin-top: 1.5rem;
}
</style>